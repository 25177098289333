import { Typography } from '@mui/material';
import styled from 'styled-components/macro';

export const FileUploaderDropzoneAntigen = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 35px 0px;
  grid-column-gap: 10px;
`;

export const FileUploaderButton = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.greenDark};
  border-radius: 3rem;
  padding: 11px 16px;
  width: 140px;
  font-size: 14px !important;
  margin: auto !important;
  text-transform: uppercase;
  font-weight: bold !important;

  button {
    padding: 4px 16px;
    font-family: light !important;
  }

  :hover {
    opacity: 0.9;
  }
`;

export const FileUploaderGreyText = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.greyMedium};
  text-align: center;
`;

import React from 'react';

import {
  SelectProps,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Box,
  Chip,
  styled,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  StyledInputWrapper,
  StyledInputInputTitle,
  StyledInputRowWrapper,
} from './UI-Library.styles';

const StyledChip = styled(Chip)`
  margin: 2px;
`;

interface StyledMUIMultiSelectProps extends SelectProps {
  fieldTitle: string;
  options: Array<{ label: string; value: string }>;
  selectedValues: string[];
  customOnChange?: (selected: string[]) => void;
}

const StyledMUIMultiSelect: React.FC<StyledMUIMultiSelectProps> = ({
  fieldTitle,
  options,
  selectedValues,
  customOnChange,
  ...selectProps
}) => {
  const handleChange = (event: SelectChangeEvent<Array<string>>) => {
    const value = event.target.value as string[];
    if (customOnChange) {
      customOnChange(value);
    }
  };

  return (
    <StyledInputRowWrapper>
      <StyledInputWrapper>
        <StyledInputInputTitle>{fieldTitle}</StyledInputInputTitle>
        <Select
          {...selectProps}
          style={{
            height: '44px',
            maxWidth: '90%',
            borderRadius: '16px',
            overflow: 'hidden',
            whiteSpace: 'text-overflow',
          }}
          multiple
          value={selectedValues}
          defaultValue={[]}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box component="div" sx={{ display: 'flex', overflow: 'visible' }}>
              {(selected as string[]).map((value) => (
                <StyledChip key={value} label={value} />
              ))}
            </Box>
          )}
          IconComponent={() => (
            <div style={{ fontSize: '30px' }}>
              <ArrowDropDownIcon
                fontSize="inherit"
                style={{ marginTop: '3px', marginRight: '3px' }}
              />
            </div>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
                maxWidth: '85%',
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              style={{ whiteSpace: 'normal' }}
              key={option.value}
              value={option.value}
            >
              <Checkbox checked={selectedValues.indexOf(option.value) > -1} />
              <ListItemText
                style={{ maxWidth: '90%' }}
                secondary={option.label}
              />
            </MenuItem>
          ))}
        </Select>
      </StyledInputWrapper>
    </StyledInputRowWrapper>
  );
};

export default StyledMUIMultiSelect;

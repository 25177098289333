import {
  Checkbox,
  FormControlLabel,
  Modal,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IFileWithMeta } from 'react-dropzone-uploader';

import CloseIcon from '@mui/icons-material/Close';
import { useWindowWidth } from '@react-hook/window-size';
import Confetti from 'react-confetti';
import { useParams } from 'react-router-dom';
import { useDeviceHook } from '../../utils/hooks/useDeviceHook';
import { Button } from '../shared/Button/Button';
import { WarningRounded } from '../../assets/iconComponents/WarningRounded';
import { SpinnerLoading } from '../shared/Spinner/SpinnerLoading';
import { GetTypographyContent } from '../shared/Typography/Typography';
import { PatientInsuranceInfo } from '../Profile/helpers';
import {
  BillingRadioControlDropdown,
  ProfileInsuranceModalButtons,
  ProfileInsuranceModalTitle,
  ProfileInsuranceRequiredMessage,
  ProfileModalTitleWrapper,
} from '../Profile/ProfileInsuranceModal.styles';
import ProfileInsuranceUpload from '../Profile/ProfileInsuranceUpload';
import { StyledUISelect } from '../shared/UI-Library/StyledUISelect';
import StyledMUIMultiSelect from '../shared/UI-Library/StyledMUISelect';
import {
  UpfrontPaymentButtonRow,
  UpfrontPaymentCloseIcon,
  UpfrontPaymentFinancialModalWrapper,
  UpfrontPaymentFinancialQuestionsWrapper,
  UpfrontPaymentFinancialReceipt,
  UpfrontPaymentFinancialReceiptTitle,
  UpfrontPaymentFinancialStepperWrapper,
  UpfrontPaymentFinancialSummary,
  UpfrontPaymentFinancialSummaryAttestation,
  UpfrontPaymentFinancialSummaryRow,
  UpfrontPaymentFinancialSummaryRowHeader,
  UpfrontPaymentFinancialSummaryRowText,
  UpfrontPaymentFinancialSummaryTitle,
  UpfrontPaymentFinancialSummaryWrapper,
  UpfrontPaymentFinancialWarningRoundedWrapper,
  UpfrontPaymentInputComponent,
  UpfrontPaymentInputRowWrapper,
  UpfrontPaymentInputTitle,
  UpfrontPaymentInputWrapper,
  UpfrontPaymentMedicaidWarningWrapper,
  UpfrontPaymentPhoneLink,
  UpfrontPaymentTextInputComponent,
} from './UpfrontPayment.styles';
import {
  GetUpfrontPaymentFinancialAssistance,
  UpfrontPaymenFinancialAssistanceResponse,
  UpfrontPaymentType,
  getCentsToDollars,
} from './Services/UpfrontPaymentService';
import { GreenFullCheckIcon } from '../../assets/iconComponents/GreenFullCheckIcon';
import { StepperIconBlank } from '../../assets/iconComponents/StepperIconBlank';
import { StepperIconFilled } from '../../assets/iconComponents/StepperIconFilled';
import { themePalette } from '../../theme';
import {
  BillingSupportNumber,
  ExtenuatingCircumstances,
  ExtenuatingCircumstancesType,
} from './types';
import { StepperIconProgress } from '../../assets/iconComponents/StepperIconProgress';

const getIncome = (householdSize: number) => {
  switch (householdSize) {
    case 2:
      return [
        { value: 19000, label: 'Less than $20,440' },
        { value: 25000, label: 'Between $20,440 and $40,880' },
        { value: 50000, label: 'Between $40,880 and $81,760' },
        { value: 95000, label: 'Between $81,760 and $122,640' },
        { value: 135000, label: 'More than $122,640' },
      ];
    case 3:
      return [
        { value: 20000, label: 'Less than $25,820' },
        { value: 35000, label: 'Between $25,820 and $51,640' },
        { value: 80000, label: 'Between $51,640 and $103,280' },
        { value: 125000, label: 'Between $103,280 and $154,920' },
        { value: 170000, label: 'More than $154,920' },
      ];
    case 4:
      return [
        { value: 28000, label: 'Less than $31,200' },
        { value: 40000, label: 'Between $31,200 and $62,400' },
        { value: 100000, label: 'Between $62,400 and $124,800' },
        { value: 140000, label: 'Between $124,800 and $187,200' },
        { value: 195000, label: 'More than $187,200' },
      ];
    case 5:
      return [
        { value: 30000, label: 'Less than $36,580' },
        { value: 50000, label: 'Between $36,580 and $73,160' },
        { value: 90000, label: 'Between $73,160 and $146,320' },
        { value: 180000, label: 'Between $146,320 and $219,480' },
        { value: 230000, label: 'More than $219,480' },
      ];
    case 6:
      return [
        { value: 35000, label: 'Less than $41,960' },
        { value: 55000, label: 'Between $41,960 and $83,920' },
        { value: 100000, label: 'Between $83,920 and $167,840' },
        { value: 210000, label: 'Between $167,840 and $251,760' },
        { value: 280000, label: 'More than $251,760' },
      ];

    case 7:
      return [
        { value: 40000, label: 'Less than $47,340' },
        { value: 75000, label: 'Between $47,340 and $94,680' },
        { value: 120000, label: 'Between $94,680 and $189,360' },
        { value: 210000, label: 'Between $189,360 and $284,040' },
        { value: 300000, label: 'More than $284,040' },
      ];

    default:
      return [
        { value: 44000, label: 'Less than $52,720' },
        { value: 80000, label: 'Between $52,720 and $105,440' },
        { value: 150000, label: 'Between $105,440 and $210,880' },
        { value: 250000, label: 'Between $210,880 and $316,320' },
        { value: 350000, label: 'More than $316,320' },
      ];
  }
};

const getAnnualIncomeCheck = (
  householdSize: number,
  income: number,
  annualIncomeCheck: number
) => {
  const incomeArray = getIncome(householdSize);
  const incomeCheck = incomeArray.find(
    (incomeVal) => incomeVal.value === income
  );

  if (incomeCheck) {
    const values = incomeCheck.label.split(' ');

    if (values[0] === 'Less') {
      return annualIncomeCheck < Number(values[2].replace(/[^0-9.]+/g, ''));
    }
    if (values[0] === 'Between') {
      return (
        annualIncomeCheck > Number(values[1].replace(/[^0-9.]+/g, '')) &&
        annualIncomeCheck < Number(values[3].replace(/[^0-9.]+/g, ''))
      );
    }
    if (values[0] === 'More') {
      return annualIncomeCheck > Number(values[2].replace(/[^0-9.]+/g, ''));
    }
  }
};

export const UpfrontFinancialAssistanceModal: React.FC<{
  financialAssistanceModal: boolean;
  setFinancialAssistanceModal: React.Dispatch<React.SetStateAction<boolean>>;
  setStripeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUpfrontBill: React.Dispatch<
    React.SetStateAction<UpfrontPaymentType | undefined>
  >;
}> = ({
  financialAssistanceModal,
  setFinancialAssistanceModal,
  setStripeModalOpen,
  setUpfrontBill,
}) => {
  const { deviceType } = useDeviceHook();
  const [isError, setCheckError] = useState(false);
  const { billingPK, adminID } =
    useParams<{ billingPK: string; adminID?: string }>();
  const [loading, setLoading] = useState(false);
  const [confirmScreen, setConfirmScreen] = useState(false);
  const [rulesAccepted, setRulesAccepted] = useState(false);
  const [annualIncomeCheck, setAnnualIncomeCheck] = useState<number>();
  const [annualIncomeCheckError, setAnnualIncomeCheckError] =
    useState<string>();
  const [financialAidResponse, setFinancialAidResponse] =
    useState<UpfrontPaymenFinancialAssistanceResponse>();
  const [selectedCircumstances, setSelectedCircumstances] = React.useState<
    ExtenuatingCircumstancesType[]
  >([]);
  const [otherCircumstance, setOtherCircumstance] = React.useState<
    string | undefined
  >('');
  const [selectedFAFile, setSelectedFAFile] = useState<IFileWithMeta | null>(
    null
  );
  const windowWidth = useWindowWidth();

  const [patientInsuranceInfo, setPatientInsuranceInfo] =
    React.useState<PatientInsuranceInfo>({
      billToInsuranceType: 'Upload',
      insuranceCompanyName: '',
      groupId: '',
      memberId: '',
      policyOwnerName: '',
      policyOwnerDob: '',
      relationshipToPolicyOwnerSelf: true,
      relationshipToPolicyOwnerDependent: false,
      relationshipToPolicyOwnerOther: false,
      insuranceFileUrls: {},
      checkError: false,
    });

  const blockNext = () => {
    if (confirmScreen) {
      if (patientFinancialInfo.medicaid === 'No' && !rulesAccepted) {
        return true;
      }
      if (patientFinancialInfo.medicaid === 'No') {
        return (
          !patientFinancialInfo.householdSize ||
          !patientFinancialInfo.income ||
          !annualIncomeCheck
        );
      }

      return Object.keys(patientInsuranceInfo.insuranceFileUrls).length < 2;
    }
    if (
      patientFinancialInfo.medicaid === 'Yes' &&
      Object.keys(patientInsuranceInfo.insuranceFileUrls).length < 2
    ) {
      return true;
    }
    if (
      patientFinancialInfo.medicaid === 'No' &&
      Object.keys(patientInsuranceInfo.insuranceFileUrls).length < 2
    ) {
      return (
        !patientFinancialInfo.householdSize || !patientFinancialInfo.income
      );
    }

    return false;
  };

  interface PatientFinancial {
    medicaid?: 'Yes' | 'No';
    householdSize?: number;
    income?: number;
  }

  const [patientFinancialInfo, setPatientFinancialInfo] =
    React.useState<PatientFinancial>({});

  useEffect(() => {
    setCheckError(false);
  }, [patientInsuranceInfo]);

  const onSubmit = async () => {
    if (!confirmScreen) {
      setConfirmScreen(true);
    } else if (
      patientFinancialInfo.medicaid !== undefined &&
      ((patientFinancialInfo.householdSize && patientFinancialInfo.income) ||
        Object.keys(patientInsuranceInfo.insuranceFileUrls).length > 1)
    ) {
      if (
        patientFinancialInfo.householdSize &&
        patientFinancialInfo.income &&
        annualIncomeCheck &&
        !getAnnualIncomeCheck(
          patientFinancialInfo.householdSize,
          patientFinancialInfo.income,
          annualIncomeCheck
        )
      ) {
        setAnnualIncomeCheckError(
          'There’s a discrepancy between the amount you selected and the amount you entered.'
        );
      } else {
        setLoading(true);
        const financialAssistanceResponse =
          await GetUpfrontPaymentFinancialAssistance(
            billingPK,
            {
              has_medicaid: patientFinancialInfo.medicaid === 'Yes',
              household_size: patientFinancialInfo.householdSize || 0,
              income: (patientFinancialInfo.income || 0) * 100,
              medicaid_insurance_card_urls: Object.keys(
                patientInsuranceInfo.insuranceFileUrls
              ).map(
                (file) => patientInsuranceInfo.insuranceFileUrls[file].fileName
              ),
              extenuating_circumstances: (() => {
                const circumstances = selectedCircumstances.filter(
                  (circumstance) => circumstance !== 'Other'
                ) as string[];
                if (otherCircumstance) {
                  circumstances.push(`Other: '${otherCircumstance}'`);
                }
                return circumstances.join(', ');
              })(),
            },
            selectedFAFile,
            adminID
          );
        setLoading(false);
        if (financialAssistanceResponse.ok) {
          const financialAssistanceJson =
            (await financialAssistanceResponse.json()) as UpfrontPaymenFinancialAssistanceResponse;
          if (financialAssistanceJson) {
            setFinancialAidResponse(financialAssistanceJson);
            setUpfrontBill(financialAssistanceJson.bill);
          }
        }
      }
    }
  };

  return (
    <Modal
      open={financialAssistanceModal}
      onClose={() => setFinancialAssistanceModal(false)}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <>
        {financialAidResponse ? (
          <UpfrontPaymentFinancialModalWrapper
            deviceType={deviceType}
            allowScroll={false}
            receipt
          >
            {financialAidResponse?.is_qualified && (
              <Confetti
                height={deviceType !== 'mobile' ? 375 : 450}
                numberOfPieces={100}
                gravity={0.1}
                initialVelocityX={5}
                initialVelocityY={5}
                recycle
                colors={[
                  themePalette.blueMedium,
                  themePalette.greenMedium,
                  themePalette.blueLight,
                  themePalette.greenLight,
                ]}
                width={deviceType !== 'mobile' ? 525 : windowWidth - 25}
              />
            )}
            <UpfrontPaymentFinancialReceipt
              deviceType={deviceType}
              noConfetti={!financialAidResponse?.is_qualified}
            >
              <UpfrontPaymentCloseIcon>
                <CloseIcon onClick={() => setFinancialAssistanceModal(false)} />
              </UpfrontPaymentCloseIcon>
              {financialAidResponse?.is_qualified ? (
                <GreenFullCheckIcon />
              ) : (
                <UpfrontPaymentFinancialWarningRoundedWrapper>
                  <WarningRounded />
                </UpfrontPaymentFinancialWarningRoundedWrapper>
              )}
              <UpfrontPaymentFinancialReceiptTitle>
                {financialAidResponse?.is_qualified
                  ? `Your bill has been adjusted to ${getCentsToDollars(
                      financialAidResponse?.bill
                        .total_patient_responsibility_amount
                    )}`
                  : `It doesn't look like your financial information qualifies for an income based reduction`}
              </UpfrontPaymentFinancialReceiptTitle>
              <div
                style={{
                  display: 'inline',
                  fontSize: '16px',
                  fontFamily: 'light',
                }}
              >
                {financialAidResponse?.is_qualified ? (
                  `Thank you for providing your ${
                    patientFinancialInfo.medicaid === 'No'
                      ? 'financial assistance'
                      : 'insurance'
                  } information!`
                ) : (
                  <>
                    {`However, you may still qualify for other discounts. Please
                    contact us at `}
                    <a
                      href={`tel:${BillingSupportNumber}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <UpfrontPaymentPhoneLink>
                        {BillingSupportNumber}
                      </UpfrontPaymentPhoneLink>
                    </a>
                    {` to discuss your options with one of our team members.`}{' '}
                  </>
                )}
              </div>
              <UpfrontPaymentButtonRow>
                <Button
                  label={
                    financialAidResponse?.is_qualified &&
                    financialAidResponse?.bill
                      .total_patient_responsibility_amount > 0
                      ? `Pay ${getCentsToDollars(
                          financialAidResponse?.bill
                            .total_patient_responsibility_amount
                        )}`
                      : 'Done'
                  }
                  onClick={() => {
                    setFinancialAssistanceModal(false);
                    if (
                      financialAidResponse?.is_qualified &&
                      financialAidResponse?.bill
                        .total_patient_responsibility_amount > 0
                    ) {
                      setStripeModalOpen(true);
                    }
                  }}
                  size="medium"
                  backgroundColor="greenDark"
                />
              </UpfrontPaymentButtonRow>
            </UpfrontPaymentFinancialReceipt>
          </UpfrontPaymentFinancialModalWrapper>
        ) : (
          <UpfrontPaymentFinancialModalWrapper
            deviceType={deviceType}
            allowScroll
          >
            <ProfileInsuranceModalTitle deviceType={deviceType}>
              <ProfileModalTitleWrapper deviceType={deviceType}>
                <div> Financial Assistance </div>
                <CloseIcon onClick={() => setFinancialAssistanceModal(false)} />
              </ProfileModalTitleWrapper>
            </ProfileInsuranceModalTitle>
            {confirmScreen ? (
              <>
                <UpfrontPaymentFinancialSummaryWrapper>
                  <UpfrontPaymentFinancialSummary>
                    <UpfrontPaymentFinancialSummaryTitle>
                      {`Information I've Provided `}
                    </UpfrontPaymentFinancialSummaryTitle>
                    <UpfrontPaymentFinancialSummaryRow>
                      <UpfrontPaymentFinancialSummaryRowHeader>
                        Do I Have Medicaid?
                      </UpfrontPaymentFinancialSummaryRowHeader>
                      <UpfrontPaymentFinancialSummaryRowText>
                        {patientFinancialInfo.medicaid}
                      </UpfrontPaymentFinancialSummaryRowText>
                    </UpfrontPaymentFinancialSummaryRow>
                    <UpfrontPaymentFinancialSummaryRow>
                      <UpfrontPaymentFinancialSummaryRowHeader>
                        {patientFinancialInfo.medicaid === 'Yes'
                          ? 'FRONT OF MY MEDICAID CARD'
                          : 'MY HOUSEHOLD SIZE INCLUDING CURRENT PREGNANCY'}
                      </UpfrontPaymentFinancialSummaryRowHeader>
                      <UpfrontPaymentFinancialSummaryRowText>
                        {patientFinancialInfo.medicaid === 'Yes'
                          ? Object.keys(patientInsuranceInfo.insuranceFileUrls)
                              .filter(
                                (fileName) =>
                                  patientInsuranceInfo.insuranceFileUrls[
                                    fileName
                                  ]?.side === 'front'
                              )
                              .join(', ')
                          : patientFinancialInfo.householdSize}
                      </UpfrontPaymentFinancialSummaryRowText>
                    </UpfrontPaymentFinancialSummaryRow>
                    <UpfrontPaymentFinancialSummaryRow>
                      <UpfrontPaymentFinancialSummaryRowHeader>
                        {patientFinancialInfo.medicaid === 'Yes'
                          ? 'BACK OF MY MEDICAID CARD'
                          : 'MY ANNUAL TOTAL HOUSEHOLD INCOME BEFORE TAXES'}
                      </UpfrontPaymentFinancialSummaryRowHeader>
                      <UpfrontPaymentFinancialSummaryRowText>
                        {patientFinancialInfo.medicaid === 'Yes'
                          ? Object.keys(patientInsuranceInfo.insuranceFileUrls)
                              .filter(
                                (fileName) =>
                                  patientInsuranceInfo.insuranceFileUrls[
                                    fileName
                                  ]?.side === 'back'
                              )
                              .join(', ')
                          : patientFinancialInfo.householdSize &&
                            getIncome(patientFinancialInfo.householdSize).find(
                              (opt) => opt.value === patientFinancialInfo.income
                            )?.label}
                      </UpfrontPaymentFinancialSummaryRowText>
                    </UpfrontPaymentFinancialSummaryRow>
                  </UpfrontPaymentFinancialSummary>
                  {patientFinancialInfo.medicaid === 'No' && (
                    <>
                      <UpfrontPaymentInputRowWrapper>
                        <UpfrontPaymentInputWrapper>
                          <UpfrontPaymentInputTitle>
                            What’s your <span>total annual</span> household
                            income before taxes?
                          </UpfrontPaymentInputTitle>
                          <UpfrontPaymentInputComponent
                            value={annualIncomeCheck}
                            error={annualIncomeCheckError}
                            onValueChange={(value) => {
                              setAnnualIncomeCheck(
                                value ? Number(value) : undefined
                              );
                            }}
                            prefix="$"
                            data-testid="annual-income-check"
                          />
                          {annualIncomeCheckError && (
                            <UpfrontPaymentInputTitle
                              isError={annualIncomeCheckError}
                            >
                              {annualIncomeCheckError}
                            </UpfrontPaymentInputTitle>
                          )}
                        </UpfrontPaymentInputWrapper>
                      </UpfrontPaymentInputRowWrapper>
                      <UpfrontPaymentFinancialSummaryAttestation>
                        I hereby certify that the information provided is true
                        and accurate. I also certify that I do not carry any
                        U.S. federal and state-funded health insurance (e.g.,
                        Medicare, Medicaid, Tricare, MedicareAdvantage). I
                        understand and agree that BillionToOne reserves the
                        right, at anytime and without notice, to modify the
                        application form, to modify or terminate this program,
                        to audit my information or to request additional
                        information.
                      </UpfrontPaymentFinancialSummaryAttestation>
                      <FormControlLabel
                        value="Upload"
                        control={
                          <Checkbox
                            onClick={() => setRulesAccepted(!rulesAccepted)}
                            checked={rulesAccepted}
                            data-testid="terms-accepted-checkbox"
                          />
                        }
                        label={GetTypographyContent({
                          content: 'I accept',
                          size: 'medium',
                          textColor: 'greyMedium',
                        })}
                      />
                    </>
                  )}
                </UpfrontPaymentFinancialSummaryWrapper>
              </>
            ) : (
              <>
                {isError && (
                  <ProfileInsuranceRequiredMessage>
                    {isError && <WarningRounded />}
                    Please fill out all required fields marked with an asterisks
                    (*)
                  </ProfileInsuranceRequiredMessage>
                )}
                <UpfrontPaymentFinancialQuestionsWrapper>
                  <StyledUISelect
                    fieldTitle="Do you also have Medicaid or an HMO?"
                    value={patientFinancialInfo.medicaid}
                    onChange={(event) => {
                      setPatientFinancialInfo({
                        ...patientFinancialInfo,
                        medicaid: event.target.value as 'Yes' | 'No',
                      });
                    }}
                    data-testid="medicaid-select"
                  >
                    <option disabled selected>
                      Select an option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </StyledUISelect>
                </UpfrontPaymentFinancialQuestionsWrapper>
                {patientFinancialInfo.medicaid === 'Yes' && (
                  <>
                    <div>
                      <BillingRadioControlDropdown $hideBorder>
                        {
                          <RadioGroup
                            value={patientInsuranceInfo.billToInsuranceType}
                            onChange={(e) =>
                              setPatientInsuranceInfo({
                                ...patientInsuranceInfo,
                                billToInsuranceType: e.target.value as
                                  | 'Entry'
                                  | 'Upload',
                              })
                            }
                          >
                            <UpfrontPaymentMedicaidWarningWrapper className="profile-modal-title profile-insurance-title">
                              With Medicaid, you will have $0 out-of-pocket
                              responsibility for the test.
                            </UpfrontPaymentMedicaidWarningWrapper>

                            {
                              // UPLOAD
                              <ProfileInsuranceUpload
                                patientInsuranceInfo={patientInsuranceInfo}
                                setPatientInsuranceInfo={
                                  setPatientInsuranceInfo
                                }
                                isError={isError}
                                upfrontBilling="medicaid_or_hmo"
                              />
                            }
                          </RadioGroup>
                        }
                      </BillingRadioControlDropdown>
                    </div>
                  </>
                )}
                {patientFinancialInfo.medicaid === 'No' && (
                  <UpfrontPaymentFinancialQuestionsWrapper>
                    <StyledUISelect
                      fieldTitle="What is your household size? (Including current pregnancy)"
                      value={patientFinancialInfo.householdSize?.toString()}
                      onChange={(event) => {
                        setPatientFinancialInfo({
                          ...patientFinancialInfo,
                          householdSize: Number(event.target.value),
                        });
                      }}
                      data-testid="household-size-select"
                    >
                      <option disabled selected>
                        Select an option
                      </option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9 or more</option>
                    </StyledUISelect>
                    {patientFinancialInfo.householdSize && (
                      <StyledUISelect
                        fieldTitle=" What’s your total household income before taxes?"
                        value={patientFinancialInfo.income?.toString()}
                        onChange={(event) => {
                          setPatientFinancialInfo({
                            ...patientFinancialInfo,
                            income: Number(event.target.value),
                          });
                        }}
                        data-testid="income-select"
                      >
                        <option disabled selected>
                          Select an option
                        </option>
                        {getIncome(patientFinancialInfo.householdSize).map(
                          (opt) => (
                            <option value={opt.value}>{opt.label}</option>
                          )
                        )}
                      </StyledUISelect>
                    )}
                    {patientFinancialInfo.income && (
                      <StyledMUIMultiSelect
                        fieldTitle="Do you have extenuating circumstances you would like us to consider
          when evaluating your application?"
                        options={ExtenuatingCircumstances.map(
                          (circumstance) => ({
                            label: circumstance,
                            value: circumstance,
                          })
                        )}
                        selectedValues={selectedCircumstances}
                        customOnChange={(value) =>
                          setSelectedCircumstances(
                            value as ExtenuatingCircumstancesType[]
                          )
                        }
                        data-testid="extenuating-circumstances-select"
                      />
                    )}
                    {selectedCircumstances.includes('Other') && (
                      <UpfrontPaymentInputRowWrapper>
                        <UpfrontPaymentInputWrapper>
                          <UpfrontPaymentInputTitle>
                            Other
                          </UpfrontPaymentInputTitle>
                          <UpfrontPaymentTextInputComponent
                            value={otherCircumstance}
                            onChange={(event) => {
                              const { value } = event.target;
                              setOtherCircumstance(value);
                            }}
                            disableUnderline
                            data-testid="other-circumstance-input"
                          />
                        </UpfrontPaymentInputWrapper>
                      </UpfrontPaymentInputRowWrapper>
                    )}
                  </UpfrontPaymentFinancialQuestionsWrapper>
                )}
              </>
            )}
            <UpfrontPaymentFinancialStepperWrapper
              marginTop={patientFinancialInfo.medicaid === undefined ? 120 : 15}
            >
              <Stepper>
                <Step>
                  <StepLabel
                    StepIconComponent={
                      confirmScreen ? StepperIconFilled : StepperIconProgress
                    }
                  />
                </Step>
                <Step>
                  <StepLabel
                    StepIconComponent={
                      confirmScreen ? StepperIconProgress : StepperIconBlank
                    }
                  />
                </Step>
              </Stepper>
            </UpfrontPaymentFinancialStepperWrapper>
            {loading ? (
              <SpinnerLoading />
            ) : (
              <ProfileInsuranceModalTitle deviceType={deviceType}>
                <ProfileInsuranceModalButtons deviceType={deviceType}>
                  <Button
                    className="upfront-cancel-button"
                    label={confirmScreen ? 'Back' : 'Cancel'}
                    onClick={() => {
                      if (confirmScreen) {
                        setConfirmScreen(false);
                      } else {
                        setFinancialAssistanceModal(false);
                      }
                    }}
                    size="medium"
                    reverse
                    textColor={confirmScreen ? 'greenDark' : 'greyMedium'}
                    backgroundColor={confirmScreen ? 'greenDark' : 'greyMedium'}
                  />
                  <Button
                    className="upfront-submit-button"
                    label={confirmScreen ? 'Submit' : 'Next'}
                    data-testid="submit-button"
                    disabled={blockNext()}
                    onClick={onSubmit}
                    size="medium"
                    backgroundColor="greenDark"
                  />
                </ProfileInsuranceModalButtons>
              </ProfileInsuranceModalTitle>
            )}
          </UpfrontPaymentFinancialModalWrapper>
        )}
      </>
    </Modal>
  );
};

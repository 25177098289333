import * as React from 'react';

export const VideoPlay = (): React.ReactElement => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="video">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.75C2.86193 4.75 2.75 4.86193 2.75 5V19C2.75 19.1381 2.86192 19.25 3 19.25H21C21.1381 19.25 21.25 19.1381 21.25 19V5C21.25 4.86192 21.1381 4.75 21 4.75H3ZM1.25 5C1.25 4.0335 2.0335 3.25 3 3.25H21C21.9665 3.25 22.75 4.03351 22.75 5V19C22.75 19.9665 21.9665 20.75 21 20.75H3C2.03351 20.75 1.25 19.9665 1.25 19V5Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.25C18.4142 3.25 18.75 3.58579 18.75 4V20C18.75 20.4142 18.4142 20.75 18 20.75C17.5858 20.75 17.25 20.4142 17.25 20V4C17.25 3.58579 17.5858 3.25 18 3.25Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3.25C6.41421 3.25 6.75 3.58579 6.75 4V20C6.75 20.4142 6.41421 20.75 6 20.75C5.58579 20.75 5.25 20.4142 5.25 20V4C5.25 3.58579 5.58579 3.25 6 3.25Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.25 9C18.25 8.58579 18.5858 8.25 19 8.25H22C22.4142 8.25 22.75 8.58579 22.75 9C22.75 9.41421 22.4142 9.75 22 9.75H19C18.5858 9.75 18.25 9.41421 18.25 9Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.25 15C18.25 14.5858 18.5858 14.25 19 14.25H22C22.4142 14.25 22.75 14.5858 22.75 15C22.75 15.4142 22.4142 15.75 22 15.75H19C18.5858 15.75 18.25 15.4142 18.25 15Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 9C1.25 8.58579 1.58579 8.25 2 8.25H5C5.41421 8.25 5.75 8.58579 5.75 9C5.75 9.41421 5.41421 9.75 5 9.75H2C1.58579 9.75 1.25 9.41421 1.25 9Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.25C2.41421 7.25 2.75 7.58579 2.75 8V10C2.75 10.4142 2.41421 10.75 2 10.75C1.58579 10.75 1.25 10.4142 1.25 10V8C1.25 7.58579 1.58579 7.25 2 7.25Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 4C3.75 3.58579 4.08579 3.25 4.5 3.25H7.5C7.91421 3.25 8.25 3.58579 8.25 4C8.25 4.41421 7.91421 4.75 7.5 4.75H4.5C4.08579 4.75 3.75 4.41421 3.75 4Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 20C3.75 19.5858 4.08579 19.25 4.5 19.25H7.5C7.91421 19.25 8.25 19.5858 8.25 20C8.25 20.4142 7.91421 20.75 7.5 20.75H4.5C4.08579 20.75 3.75 20.4142 3.75 20Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_10"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 4C15.75 3.58579 16.0858 3.25 16.5 3.25H19.5C19.9142 3.25 20.25 3.58579 20.25 4C20.25 4.41421 19.9142 4.75 19.5 4.75H16.5C16.0858 4.75 15.75 4.41421 15.75 4Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_11"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 20C15.75 19.5858 16.0858 19.25 16.5 19.25H19.5C19.9142 19.25 20.25 19.5858 20.25 20C20.25 20.4142 19.9142 20.75 19.5 20.75H16.5C16.0858 20.75 15.75 20.4142 15.75 20Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_12"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 15C1.25 14.5858 1.58579 14.25 2 14.25H5C5.41421 14.25 5.75 14.5858 5.75 15C5.75 15.4142 5.41421 15.75 5 15.75H2C1.58579 15.75 1.25 15.4142 1.25 15Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_13"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 13.25C2.41421 13.25 2.75 13.5858 2.75 14V16C2.75 16.4142 2.41421 16.75 2 16.75C1.58579 16.75 1.25 16.4142 1.25 16V14C1.25 13.5858 1.58579 13.25 2 13.25Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_14"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 13.25C22.4142 13.25 22.75 13.5858 22.75 14V16C22.75 16.4142 22.4142 16.75 22 16.75C21.5858 16.75 21.25 16.4142 21.25 16V14C21.25 13.5858 21.5858 13.25 22 13.25Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_15"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 7.25C22.4142 7.25 22.75 7.58579 22.75 8V10C22.75 10.4142 22.4142 10.75 22 10.75C21.5858 10.75 21.25 10.4142 21.25 10V8C21.25 7.58579 21.5858 7.25 22 7.25Z"
        fill="#F0604B"
      />
      <path
        id="Vector (Stroke)_16"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1364 8.84402C10.3749 8.71185 10.6663 8.7195 10.8975 8.864L14.8975 11.364C15.1168 11.5011 15.25 11.7414 15.25 12C15.25 12.2586 15.1168 12.4989 14.8975 12.636L10.8975 15.136C10.6663 15.2805 10.3749 15.2882 10.1364 15.156C9.89796 15.0238 9.75 14.7726 9.75 14.5V9.5C9.75 9.22736 9.89796 8.97619 10.1364 8.84402ZM11.25 10.8532V13.1468L13.0849 12L11.25 10.8532Z"
        fill="#F0604B"
      />
    </g>
  </svg>
);

import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useRecoilValue } from 'recoil';
import rhdVideo from '../../../assets/images/fetalRhdVideo.png';
import { getUserAuth } from '../../../recoil/selectors';
import { SendActionType } from '../Services/SharedServices';
import VideoFeedbackWidget from './VideoFeedback';
import { useDeviceHook } from '../../../utils/hooks/useDeviceHook';

const RhDVideoEmbed: React.FC<{
  videoLocation: 'rhd_page' | 'rhd_learn_more';
  reqID: string;
}> = ({ videoLocation, reqID }) => {
  const [showFeedback, setShowFeedback] = React.useState(false);
  const auth = useRecoilValue(getUserAuth);
  const { deviceType } = useDeviceHook();
  return (
    <>
      <ReactPlayer
        playing
        id="rhd-video-player"
        url="https://player.vimeo.com/video/960668334?playsinline=0"
        light={rhdVideo}
        height={`${deviceType === 'mobile' ? '328px' : '295px'}`}
        width={`${deviceType === 'mobile' ? '100%' : '528px'}`}
        style={{ justifySelf: 'center', marginBottom: '20px' }}
        controls
        vimeoConfig={{ iframeParams: { fullscreen: 1 } }}
        data-testid="rhd-video-player"
        playsinline={false}
        onPlay={async () => {
          await SendActionType('RhDVideoClicked', reqID, auth?.access_token, {
            videoLocation,
          });
          // const el = document.getElementById('rhd-video-player');
          // if (el) {
          //   await screenfull.request(el);
          // }
          setShowFeedback(true);
        }}
      />
      {showFeedback && (
        <VideoFeedbackWidget
          videoType="rhd"
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          reqID={reqID}
          videoLocation={videoLocation}
        />
      )}
    </>
  );
};

export default RhDVideoEmbed;

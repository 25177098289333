import React from 'react';
import ReactPlayer from 'react-player/vimeo';
import { useRecoilValue } from 'recoil';
import { UiActionType } from 'recoil/types';
import { VideoDiv } from './VideoEmbed.styles';
import lowRisk22qVideo from '../../../assets/images/lowRisk22qVideo.png';
import lowRiskAneuploidyVideo from '../../../assets/images/lowRiskAneuploidyVideo.png';
import fetalRhdVideo from '../../../assets/images/fetalRhdVideo.png';
import aneuploidyVideo from '../../../assets/images/unityAneuploidyInfoVideo.png';
import { useDeviceHook } from '../../../utils/hooks/useDeviceHook';
import { SendActionType } from '../Services/SharedServices';
import { getUserAuth } from '../../../recoil/selectors';

const getWidth = (deviceType: string) => {
  if (deviceType === 'mobile') return '328px';
  if (deviceType === 'tablet') return '528px';
  if (deviceType === 'desktop') return '760px';
};

const getHeight = (deviceType: string) => {
  if (deviceType === 'mobile') return '328px';
  if (deviceType === 'tablet') return '296px';
  if (deviceType === 'desktop') return '442px';
};

const getVideoImage = (
  videoStyle: 'aneuploidyInfo' | 'lowRiskAneuploidy' | '22q' | 'rhd'
) => {
  if (videoStyle === 'lowRiskAneuploidy') return lowRiskAneuploidyVideo;
  if (videoStyle === 'aneuploidyInfo') return aneuploidyVideo;
  if (videoStyle === '22q') return lowRisk22qVideo;
  if (videoStyle === 'rhd') return fetalRhdVideo;
};

const getVideoUrl = (
  videoStyle: 'aneuploidyInfo' | 'lowRiskAneuploidy' | '22q' | 'rhd'
) => {
  if (videoStyle === 'lowRiskAneuploidy')
    return 'https://player.vimeo.com/video/1035771695?playsinline=0';
  if (videoStyle === 'aneuploidyInfo')
    return 'https://player.vimeo.com/video/960668298?playsinline=0';
  if (videoStyle === '22q')
    return 'https://player.vimeo.com/video/1035771746?playsinline=0';
  if (videoStyle === 'rhd')
    return 'https://player.vimeo.com/video/960668334?playsinline=0';
};

const getActionType = (
  videoStyle: 'aneuploidyInfo' | 'lowRiskAneuploidy' | '22q' | 'rhd'
): UiActionType => {
  if (videoStyle === 'lowRiskAneuploidy')
    return 'LowRiskAneuploidyVideoClicked';
  if (videoStyle === 'aneuploidyInfo') return 'AneuploidyVideoClicked';
  if (videoStyle === '22q') return 'Del22qVideoClicked';
  if (videoStyle === 'rhd') return 'RhDVideoClicked';
  return 'AneuploidyVideoClicked';
};

const Aneuploidy22qVideoEmbed: React.FC<{
  requisitionID: string;
  videoStyle: 'aneuploidyInfo' | 'lowRiskAneuploidy' | '22q' | 'rhd';
}> = ({ requisitionID, videoStyle }) => {
  const { deviceType } = useDeviceHook();
  const auth = useRecoilValue(getUserAuth);

  return (
    <VideoDiv>
      <ReactPlayer
        playing
        url={getVideoUrl(videoStyle)}
        light={getVideoImage(videoStyle)}
        height={getHeight(deviceType)}
        width={getWidth(deviceType)}
        controls
        data-testid="unity-video-player"
        data-vimeo-playsinline
        data-vimeo-autoplay
        onPlay={async () => {
          await SendActionType(
            getActionType(videoStyle),
            requisitionID,
            auth?.access_token
          );
        }}
      />
    </VideoDiv>
  );
};

export default Aneuploidy22qVideoEmbed;
